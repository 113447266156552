import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data

  const { frontmatter, html, fields } = markdownRemark

  const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid

  const renderTag = tag => <span key={tag}><Link to={`/tags/${tag}`}>{tag}</Link> </span>;

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div style={{maxHeight: 700, overflow: 'hidden'}}>
        <Img fluid={featuredImgFluid} className="mb-sm-3 mb-md-4 mb-lg-0" />
      </div>
      <div className="container py-4 py-md-5">
        <div className="row">
          <article className="col col-lg-10 markdown-article article bg-white py-lg-5">
            <div className="row justify-content-center py-lg-5">

              <div className="col-lg-10">
                <header className="mb-5 article-header">
                  <h1 className="mb-0">{frontmatter.title}</h1>
                  <p className="text-muted">{frontmatter.date} • {fields.readingTime.text}</p>
                </header>
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {frontmatter.tags && frontmatter.tags.length && (
                  <footer className="text-muted small">
                    Filed under {frontmatter.tags.map(tag => renderTag(tag))}
                  </footer>
                )}
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query(
    $path: String!,
    $highlight: String!,
    $shadow: String!
  ) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 2000,
              maxHeight: 800,
              cropFocus: CENTER,
              background: $highlight,
              duotone: {
                highlight: $highlight,
                shadow: $shadow,
              }) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
